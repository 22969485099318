import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Hero } from "@components/Hero/Hero";

export const EquipmentHero = () => {
	const props = {
		title: 'Simplified Instrument Management for Smoother Lab Operations',
		description: 'Seamless equipment scheduling, booking, and maintenance all help your research activities uninterrupted.',
		image: (
			<StaticImage
				loading="eager"
				src="../../_assets/equipment-product.png"
				alt="Genemod Equipment Hero Image"
			/>
		)
	}

	return <Hero {...props} tabletTextAlignment="center" heroTextContainerSize="small" />
};
