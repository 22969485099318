import React from "react";
import { PageLayout } from "@components/index";
import { EquipmentHero } from "./_components/_EquipmentHero/_EquipmentHero";
import { JoinInstitutionsV2 } from "pages/landingpagev2/_components/_JoinInstitutionsV2/_JoinInstitutionsV2";
import Margins from "@components/Margins/Margins";
import * as styles from "./equipments.module.scss";
import { StaticImage } from "gatsby-plugin-image";
import FeatureCard, {
	FeatureCardLayout,
	FeatureCardProps,
} from "@components/FeatureCard/FeatureCard";

const cards: FeatureCardProps[] = [
	{
		title: "Visualize Instrument Availability and Scheduling",
		image: (
			<StaticImage
				src={"./_assets/equipment_img_1.png"}
				alt="Visualize Instrument Availability and Scheduling"
			/>
		),
		list: [
			"Get a complete view of all available instruments to optimize usage and minimize downtown.",
			"Use the visual schedule to quickly see when equipment is booked, making planning easier and reducing overlaps.",
			"Promote effective coordination among team members to optimize instrument usage and keep up a continuous workflow.",
		],
	},
	{
		title: "User-Friendly Booking Experience",
		image: (
			<StaticImage
				src={"./_assets/equipment_img_2.png"}
				alt="User-Friendly Booking Experience"
			/>
		),
		list: [
			"Our design makes booking a breeze – just click, drag, and secure your equipment for your preferred date and time.",
			"The platform intuitively displays availability to prevent double-bookings and improve collaboration.",
			"Simplify the reservation process by giving researchers access to the tools they need precisely when they need them.",
		],
		orientation: "right",
	},
	{
		title: "Comprehensive Equipment Management",
		image: (
			<StaticImage
				src={"./_assets/equipment_img_3.png"}
				alt="Comprehensive Equipment Management"
			/>
		),
		list: [
			"Maintain a detailed list of instruments for easy access and timely usage.",
			"Empower team members to add, modify, or even track maintenance schedules so that equipment stays reliable and long-lasting.",
			"Foster a proactive approach to equipment care, maximizing performance and reducing unexpected disruptions.",
		],
	},
];

const Equipments = () => {
	return (
		<PageLayout seoOptions={{ type: "PREDEFINED", pageName: "equipments" }}>
			<Margins>
				<EquipmentHero />
				<FeatureCardLayout className={styles.layout}>
					{cards.map((card, index) => {
						return <FeatureCard {...card} key={index} />;
					})}
				</FeatureCardLayout>
				<JoinInstitutionsV2
					outerContainerClassName={styles.joinInstitutions}
				/>
			</Margins>
		</PageLayout>
	);
};

export default Equipments;
